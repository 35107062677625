.carousel {
    overflow: hidden;
    
}

.inner {
    white-space: nowrap;
    transition: transform 0.3s;
    font-size: larger;
}

.carousel-item {
    display: inline-flex;
    align-items: center;
    padding-bottom: 50px;
    /* margin-top: 190px; */
    justify-content: center;
    /* height: 150px; */
    /* background-color: green; */
    color: #fff;
}

.indicators {
    display: flex;
    justify-content: center;
}

.indicators>button {
    /* margin: 5px; */
}

.indicators>button.active {
    /* background-color: green; */
    color: #fff;
}